import { defaultIconProps, IconProps } from './IconProps';

export const IconFilePreview: import('react').FC<IconProps> = ({ size, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
    <g id="file-02 - ">
      <path
        id="Icon"
        d="M9.33341 7.33398H5.33341M6.66675 10.0007H5.33341M10.6667 4.66732H5.33341M13.3334 4.53398V11.4673C13.3334 12.5874 13.3334 13.1475 13.1154 13.5753C12.9237 13.9516 12.6177 14.2576 12.2414 14.4493C11.8136 14.6673 11.2535 14.6673 10.1334 14.6673H5.86675C4.74664 14.6673 4.18659 14.6673 3.75877 14.4493C3.38244 14.2576 3.07648 13.9516 2.88473 13.5753C2.66675 13.1475 2.66675 12.5874 2.66675 11.4673V4.53398C2.66675 3.41388 2.66675 2.85383 2.88473 2.426C3.07648 2.04968 3.38244 1.74372 3.75877 1.55197C4.18659 1.33398 4.74664 1.33398 5.86675 1.33398H10.1334C11.2535 1.33398 11.8136 1.33398 12.2414 1.55197C12.6177 1.74372 12.9237 2.04968 13.1154 2.426C13.3334 2.85383 13.3334 3.41388 13.3334 4.53398Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

IconFilePreview.defaultProps = defaultIconProps;
